<template>
  <div
    class="product-reco-editorial-mood-image flex flex-col md:grid grid-cols-2 gap-y-1 md:gap-y-0 md:gap-x-1 mb-1"
  >
    <div class="row-span-2 h-full product-reco-editorial-mood-image__left">
      <slot name="left" />
    </div>
    <div>
      <div class="h-auto mb-1 product-reco-editorial-mood-image__right-top">
        <slot name="rightTop" />
      </div>
      <div class="product-reco-editorial-mood-image__right-bottom">
        <slot name="rightBottom" />
      </div>
    </div>
  </div>
</template>
